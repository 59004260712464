<template>
  <Splide class="gallary"
    :options="{ rewind: true, perPage: 1, arrows: false, type: 'fade', autoplay: true, interval: 2000, speed: 1000 }"
    aria-label="My Favorite Images" @splide:drag="onInteraction" @splide:click="onInteraction">
    <SplideSlide>
      <img src="../assets/carousel_01.webp" alt="Slide 1">
    </SplideSlide>
    <SplideSlide>
      <img src="../assets/carousel_02.webp" alt="Slide 2">
    </SplideSlide>
    <SplideSlide>
      <img src="../assets/carousel_03.webp" alt="Slide 3">
    </SplideSlide>
    <SplideSlide>
      <img src="../assets/carousel_04.webp" alt="Slide 4">
    </SplideSlide>
    <SplideSlide>
      <img src="../assets/carousel_05.webp" alt="Slide 5">
    </SplideSlide>
  </Splide>
</template>

<script>
export default {
  name: 'PhotoCarousel',
  data() {
    return {
      interacted: false
    }
  },
  methods: {
    onInteraction() {
      if (!this.interacted) {
        window.mixpanel.track("Interacted with Carousel")
        console.log('interaction');
      }
      this.interacted = true;
    }
  }
}
</script>

<style>
@import '@splidejs/vue-splide/css';

.gallary {
  margin: 0px auto;
  margin-bottom: 1.5em;
}

.splide__slide img {
  width: min(52%, var(--screen-max-width));
  height: auto;
}

.splide__pagination {
  bottom: -2em;
}

.splide__pagination__page {
  border-radius: 0;
  height: 3px;
  width: 28px;
  transform: scale(1) !important;
}
</style>