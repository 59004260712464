<template>
  <div class="page">
    <div class="container">
      <img class="p365logo" alt="Perfect365 Logo" src="../assets/perfect365_logo_white.webp">

      <h2 class="continue" :onClick="triedInvalidTap">Tap to Continue</h2>
      <AppDownloadButton :cookieState="cookieState" campaign="app_store" />
    </div>

    <div class="spacer"></div>
    <LegalFooter variant="variant_b" />
  </div>
</template>

<script>
import LegalFooter from '../components/LegalFooter.vue';
import AppDownloadButton from '../components/AppDownloadButton.vue';

export default {
  name: 'SassyPage',
  components: {
    LegalFooter,
    AppDownloadButton
  },
  methods: {
    triedInvalidTap() {
      window.mixpanel.track('Tapped "Tap to Continue"')
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto');

@font-face {
  font-family: 'Sign Painter HouseBrush';
  src: url(../assets/SignPainter-HouseBrush.otf) format('opentype');
}

.page {
  --screen-max-width: 380px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  margin: 0;
  padding: 0;

  color: white;

  min-height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  background: url(../assets/variant_b_background.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100%;
}


.container {
  padding-top: 32px;
  margin-left: 24px;
  margin-right: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.split {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: var(--screen-max-width);
  margin: 0 auto;
  margin-top: 24px;
}

.p365logo {
  max-width: var(--screen-max-width);
  width: clamp(5rem, 80%, 25rem);
  height: auto;
  margin: 0 auto;
  position: absolute;
  top: 3rem;
  left: 0;
  right: 0;
}

img.app-icon {
  width: 69px;
  height: 81px;
  margin-right: 12px;
  object-fit: cover;
}

h2.app-title {
  display: block;
  color: #FFF;
  text-shadow: 0px 1px 6px rgba(17, 9, 32, 0.40);
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  text-align: left;
  padding: 0;
  margin: 0;

  /* Make font fluid up to 380px, ending at 24px, minimum 16px */
  font-size: clamp(16px, calc(1rem + ((1vw - 3.2px) * 13.3333)), 24px);
  min-height: 0vw;

}

h2.continue {
  font-family: "Sign Painter HouseBrush";
  font-size: clamp(5rem, 10vw, 8rem);
  font-weight: 100;

  margin-bottom: 0;

  cursor: default;
  user-select: none;
}
</style>
