<template>
  <button @click="downloadApp" :class="campaign">
    Download The App
  </button>
</template>
<script>
export default {
  name: 'AppDownloadButton',
  methods: {
    downloadApp() {
      // Navigate
      let boundLambda = function () {
        //console.log("Clicked Download App after " + this.timer + "ms")
        clearTimeout(this.downloadTimer)
        window.location.href = this.clickURL
      }.bind(this)

      this.downloadTimer = setTimeout(boundLambda, 300)

      // Track...
      window.mixpanel.track("Clicked Download App",
        { time_taken_ms: this.timer, clickURL: this.clickURL },
        { send_immediately: true },
        boundLambda
      )
    },
    timerTick() {
      this.timer += this.timerResolution
    }
  },
  props: {
    cookieState: {
      type: String,
      default: null
    },
    campaign: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      clickURL: "https://apps.apple.com/US/app/id1591342284",
      timer: 0,
      timerID: 0,
      timerResolution: 100,
      downloadTimer: null
    }
  },
  mounted() {
    // Set up the timer
    this.timer = 0
    this.timerID = setInterval(this.timerTick, this.timerResolution)

    try {
      let oneLinkURL = "https://perfect365video.onelink.me/K99x";
      let mediaSource = { defaultValue: "WEB2APP_321" };
      let campaign = { defaultValue: "FB_W2A_Install" };
      let channel = { defaultValue: "FB" };
      let afSub1 = { keys: ["fbclid"] };
      let custom_ss_ui = { paramKey: "af_ss_ui", defaultValue: "true" };

      //Call the function after embedding the code through a global parameter on the window object called window.AF_SMART_SCRIPT.
      //Onelink URL is generated.
      let result = window.AF_SMART_SCRIPT.generateOneLinkURL({
        oneLinkURL: oneLinkURL,
        afParameters: {
          mediaSource: mediaSource,
          campaign: campaign,
          channel: channel,
          afSub1: afSub1,
          afCustom: [
            custom_ss_ui
          ]
        }
      });

      if (result.clickURL) {
        this.clickURL = result.clickURL;
      }
    } catch (error) {
      console.log("Appsflyer Smart Script Error: ", error)
    }
  }
}
</script>

<style scoped>
button.original {
  background: linear-gradient(328deg, #8248EB 0%, #5959F6 100%);
  color: white;
  width: 100%;
  margin: 24px auto;
  max-width: var(--screen-max-width);
}

button.original:hover {
  cursor: pointer;
  background: linear-gradient(328deg, #6A2FD7 0%, #3B3BDB 100%);
}

button.app_store {
  background: url('../assets/app-store-badge.svg') no-repeat center center / contain;
  user-select: none;
  color: transparent;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  max-height: 70px;
  max-width: 320px;
  width: 70%;
  margin-top: 16px;
  margin-bottom: 16px;
}

button.app_store:hover {
  cursor: pointer;
}
</style>