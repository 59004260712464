<template>
  <OriginalPage v-if="pageVariant == 'original'" :download_button="button_variant"></OriginalPage>
  <SassyPage v-if="pageVariant == 'sassy'"></SassyPage>
</template>

<script>
import OriginalPage from './pages/OriginalPage.vue';
import SassyPage from './pages/SassyPage.vue';

export default {
  name: 'App',
  components: {
    OriginalPage,
    SassyPage
  },
  data() {
    return {
      cookieState: "accepted",
      pageVariant: 'original',
      button_variant: 'original'
    }
  },
  mounted() {

    // Check for query variables, and set if needed.
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const entries = urlParams.entries();
    for (const entry of entries) {
      if (entry[0] == 'page_variant' || entry[0] == 'download_button_variant') {
        localStorage.setItem(entry[0], entry[1]);
      }
    }

    this.pageVariant = this.generateABTest('page_variant', [
      {
        name: 'original',
        weight: 0.20
      },
      {
        name: 'sassy',
        weight: 0.80
      },
    ])

    if (this.pageVariant === 'original') {
      this.button_variant = this.generateABTest('download_button_variant', [
        {
          name: 'original',
          weight: 0.5
        },
        {
          name: 'app_store',
          weight: 0.5
        }
      ])
    }
    else {
      // Variant B will always have the app store...
      this.button_variant = this.generateABTest('download_button_variant', [
        {
          name: 'app_store',
          weight: 1
        }
      ])
    }

    window.mixpanel.track_pageview()
  },
  methods: {
    generateABTest(name, weight_values) {
      if (localStorage.getItem(name)) {
        return localStorage.getItem(name);
      } else {
        const random = Math.random();
        let weight_sum = 0;
        let variant = null;

        for (let i = 0; i < weight_values.length; i++) {
          weight_sum += weight_values[i].weight;
          if (random <= weight_sum) {
            variant = weight_values[i].name;
            break;
          }
        }

        localStorage.setItem(name, variant);
        window.mixpanel.register({
          [name]: variant
        })
        return variant;
      }
    }
  }
}
</script>

<style>
body,
html {
  margin: 0;
  padding: 0;
  min-height: 100%;
}

html {
  height: 100%;
}

body {
  display: flex;
}

#app {
  min-height: 100%;
  display: flex;
  width: 100%;
}

button {
  display: flex;
  padding: 14px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  transition: 0.1s ease-in-out background;

  border-radius: 4px;
  border-width: 1px;
  border-color: transparent;

  font-size: 14px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.28px;

  text-transform: uppercase;
}

button:hover {
  cursor: pointer;
}

.spacer {
  flex-grow: 1;
}
</style>