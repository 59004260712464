<template>
  <div class="page">
    <div class="container">
      <img class="p365logo" alt="Perfect365 Logo" src="../assets/perfect365_logo_mono_lightened.png">

      <div class="split">
        <img class="app-icon" alt="App Icon" src="../assets/app_icon.png">
        <h2 class="app-title">The Easiest App to Beautify Your Video Selfies!</h2>
      </div>

      <AppDownloadButton :cookieState="cookieState" :campaign="download_button" />
      <PhotoCarousel />
    </div>

    <!-- Footer -->
    <div class="spacer"></div>
    <LegalFooter />
  </div>
</template>

<script>
import LegalFooter from '../components/LegalFooter.vue';
import AppDownloadButton from '../components/AppDownloadButton.vue';
import PhotoCarousel from '../components/PhotoCarousel.vue';

export default {
  name: 'OriginalPage',
  components: {
    LegalFooter,
    AppDownloadButton,
    PhotoCarousel
  },
  props: {
    download_button: {
      type: String,
      default: 'original'
    }
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto');

.page {
  --screen-max-width: 380px;
  margin: 0;
  padding: 0;
  background: linear-gradient(133deg, #A7D1F6 0%, #DB9CD6 100%);
  min-height: 100%;
  position: relative;

  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  margin: 0;
  padding: 0;

  color: white;

  min-height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
}

.container {
  padding-top: 32px;
  margin-left: 24px;
  margin-right: 24px;
}

.split {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: var(--screen-max-width);
  margin: 0 auto;
  margin-top: 24px;
}

.p365logo {
  max-width: var(--screen-max-width);
  width: 100%;
  height: auto;
  margin: 0 auto;
}

img.app-icon {
  width: 69px;
  height: 81px;
  margin-right: 12px;
  object-fit: cover;
}

h2.app-title {
  display: block;
  color: #FFF;
  text-shadow: 0px 1px 6px rgba(17, 9, 32, 0.40);
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  text-align: left;
  padding: 0;
  margin: 0;

  /* Make font fluid up to 380px, ending at 24px, minimum 16px */
  font-size: clamp(16px, calc(1rem + ((1vw - 3.2px) * 13.3333)), 24px);
  min-height: 0vw;
}
</style>
