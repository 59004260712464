<template>
  <footer :class="variant">
    <div><a href="https://www.perfect365.com/about/terms-of-services/">Terms of Service</a><span>|</span><a
        href="https://www.perfect365.com/about/privacy-policy/">Privacy Policy</a></div>
    <p>©2023 perfect365.com. All rights reserved.</p>
  </footer>
</template>

<script>
export default {
  name: 'LegalFooter',
  props: {
    variant: {
      type: String,
      default: 'variant_a'
    }
  }
}
</script>

<style scoped>
* {
  font-size: 12px;
  color: white;
}

footer {
  padding-top: 24px;
  z-index: 2;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
}

footer.variant_b {
  padding-bottom: 48px;
}

span {
  margin-left: 5px;
  margin-right: 5px;
}

a {
  text-decoration: none;
}

p {
  /* 11px bottom */
  margin: 0 0 11px 0;
}
</style>
